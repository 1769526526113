import React from "react";
import {Dropdown, Menu} from "antd";
import {formatPhoneNumberForDisplay} from "../../../util/helpers";
import './SelectSender.less';
import IntlMessages from "../../../util/IntlMessages";


const SelectSender = ({selectedValue, senderList, updateSenderNumber}) => {

  const senders = () => (
    <Menu onClick={e => updateSenderNumber(e.item.props['data-text'])}>
      {senderList && senderList.map((option, index) =>
        <Menu.Item key={index} data-text={option.number}>
          {option.customName || option.number}
        </Menu.Item>,
      )}
    </Menu>
  );

  const getNumberToShow = () => {
    if (selectedValue) {
      return selectedValue;
    }

    if (!senderList) {
      return '';
    }

    const defaultNumber = senderList.find(entry => entry.isDefault);
    return defaultNumber && defaultNumber.number;
  };

  const getCustomName = () => {
    const number = getNumberToShow();
    return senderList.find(sender => sender.number === number)?.customName;
  }

  return (
    <Dropdown className='select-sender' overlay={senders()} placement="topCenter" trigger={['click']}>
      <div className="sender hover-pointer">
        <span className='mobile-hide'><IntlMessages id="chats.sender"/>: </span>
        <p>{getCustomName() || formatPhoneNumberForDisplay(getNumberToShow())}</p>
        <i className="gx-icon-btn icon icon-menu-down z-index-2 no-hover-action"/>
      </div>
    </Dropdown>
  )
};

export default SelectSender;
