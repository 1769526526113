import React from "react";
import { injectIntl } from 'react-intl';
import { formatTime, hourMinuteOnly } from "../../../../util/helpers";
import MyAvatar from "../MyAvatar";
import { PaperClipOutlined } from '@ant-design/icons';
import IntlMessages from "../../../../util/IntlMessages";

const ReceivedMessageCell = ({message, contact, intl}) => {

    return (
        <div>
            <div className="gx-chat-item">
                <div className='avatar-container'>
                    <MyAvatar number={message.from}
                              name={contact ? `${contact.properties.name} ${contact.properties.surname}` : null}/>
                </div>
                <div className="gx-bubble-block">
                    <div className="gx-bubble" title={formatTime(message.time)}>
                        <div className="gx-message">{message.body}</div>
                    </div>
                </div>
            </div>
            <div className="incoming-message-details">
                <span className="bubble-from"><IntlMessages id="to"/>: {message.to}</span>
                {message.properties?.attachments?.length ? (
                    <a href={message.properties.attachments[0].fileUrl} target="_blank" rel="noopener noreferrer" className="attachment">
                        <PaperClipOutlined title={intl.formatMessage({id: 'message.downloadAttachment'})} />
                    </a>
                ) : null}
                <br/>
                <span className='bubble-time'>{hourMinuteOnly(message.time)}</span>
            </div>
        </div>
    )
};

export default injectIntl(ReceivedMessageCell);
