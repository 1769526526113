import React from "react";
import IntlMessages from "../../../../util/IntlMessages";
import './ChatLimitWarning.less';

const ChatLimitWarning = ({isArchive, conversationsLength}) => {
    return <>
        {!isArchive && conversationsLength >= 1000 && (
            <div className="chat-limit">
                <IntlMessages id="conversations.limit"/>
            </div>
        )}
    </>;
};

export default ChatLimitWarning;
