const authenticationExcludedUrls = [
    '/channels/login',
    '/channels/choose-account',
    '/channels/mobile-login',
    '/shoper/mobile-login',
    '/shoper/login',
    '/livechat/login',
    '/invitation',
    '/videos/upload',
    '/videos/player',
    '/qr-code',
    '/newsletter',
    '/unsubscribing'
];

export const isAuthenticationExcludedUrl = () => authenticationExcludedUrls.some(url => window.location.href.includes(url));
