import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import ChatNew from "../components/chat/ChatNew/ChatNew";
import { BULK_MESSAGING } from "../util/permissions";
import { AccountStatus } from "../util/account";

const App = ({history, match, isBulkMessagingEnabled, isPopupEnabled, isWithoutOverdue, isLoadedPhoneNumbers}) => {

    if (!isWithoutOverdue && !['/new-plans', '/balance', '/payments'].includes(document.location.pathname)) {
        history.push('/new-plans');
        return null;
    }

    return (
        <div className="gx-main-content-wrapper no-padding">
            <Switch>
                <Route path={`${match.url}plans`}
                       component={asyncComponent(() => import('./plans/planList'))}/>
                <Route path={`${match.url}payments`}
                       component={asyncComponent(() => import('./payments'))}/>
                <Route path={`${match.url}balance/:action?`}
                       component={asyncComponent(() => import('./balance'))}/>
                <Route path={`${match.url}new-plan`}
                       component={asyncComponent(() => import('./plans/newPlan'))} exact/>
                <Route path={`${match.url}new-plan/sms`}
                       component={asyncComponent(() => import('./plans/smsPlan'))} exact/>
                <Route path={`${match.url}new-plan/whatsapp`}
                       component={asyncComponent(() => import('./plans/whatsAppPlan'))} exact/>
                <Route path={`${match.url}new-plans`}
                       component={asyncComponent(() => import('./plans/monetaryAndLeadPlan'))} exact/>
                <Route path={`${match.url}shoper/login`}
                       component={asyncComponent(() => import('./shoper/login'))}/>
                <Route path={`${match.url}shoper/mobile-login`}
                       component={asyncComponent(() => import('./shoper/mobileLogin'))}/>
                <Route path={`${match.url}channels/mobile-login`}
                       component={asyncComponent(() => import('./channels/mobileLogin'))}/>
                <Route path={`${match.url}channels/choose-account`}
                       component={asyncComponent(() => import('./channels/chooseAccount'))}/>
                <Route path={`${match.url}channels/login`}
                       component={asyncComponent(() => import('./channels/login'))}/>
                <Route path={`${match.url}livechat/login`}
                       component={asyncComponent(() => import('./livechat/login'))}/>
                <Route path={`${match.url}videos/player/:videoId/:linkId?`}
                       component={asyncComponent(() => import('./videos/videoPlayer'))} exact/>
                <Route path={`${match.url}videos/upload/:videoKey`}
                       component={asyncComponent(() => import('./videos/videoMobileUploader'))} exact/>
                <Route path={`${match.url}qr-code/:uuid`}
                       component={asyncComponent(() => import('./qrCode'))} exact/>
                <Route path={`${match.url}invitation`}
                       component={asyncComponent(() => import('./zenon/invitation'))}/>
                <Route path={`${match.url}newsletter/:campaignName`}
                       component={asyncComponent(() => import('./newsletter'))} exact/>
                <Route path={`${match.url}unsubscribing/:contactId`}
                       component={asyncComponent(() => import('./newsletterUnsubscribing'))} exact/>

                {isLoadedPhoneNumbers && isWithoutOverdue && (
                    <>
                        <Route path={`${match.url}no-phone-number`}
                               component={asyncComponent(() => import('./NoPhoneNumber'))}/>
                        <Route path={`${match.url}my-dashboard`}
                               component={asyncComponent(() => import('./dashboard'))}/>
                        <Route path={`${match.url}chat`}
                               render={() => <ChatNew isArchive={false}/>}/>
                        <Route path={`${match.url}in-built-apps/contacts`}
                               component={asyncComponent(() => import('./inBuiltApps/Contact'))}/>
                        <Route path={`${match.url}my-archives`}
                               render={() => <ChatNew isArchive={true}/>}/>
                        <Route path={`${match.url}settings/livechat`}
                               component={asyncComponent(() => import('./settings/livechat'))}/>
                        <Route path={`${match.url}settings/intercom`}
                               component={asyncComponent(() => import('./settings/intercom'))}/>
                        <Route path={`${match.url}settings/slack`}
                               component={asyncComponent(() => import('./settings/slack'))}/>
                        <Route path={`${match.url}phone-book`}
                               component={asyncComponent(() => import('./zenon/phoneBook'))}/>
                        <Route path={`${match.url}bulk-send`}
                               component={asyncComponent(() => import('./zenon/bulkSend'))}/>
                        <Route path={`${match.url}billing-data`}
                               component={asyncComponent(() => import('./billingData'))}/>
                        <Route path={`${match.url}phone-numbers/edit/:id`}
                               component={asyncComponent(() => import('./phoneNumbers/phoneNumberEdit'))} exact/>
                        <Route path={`${match.url}phone-numbers`}
                               component={asyncComponent(() => import('./phoneNumbers'))} exact/>

                        {isBulkMessagingEnabled && (
                            <>
                                <Route path={`${match.url}message-templates/new`}
                                       component={asyncComponent(() => import('./messageTemplates/messageTemplateAdd'))}
                                       exact/>
                                <Route path={`${match.url}message-templates/edit/:templateName`}
                                       component={asyncComponent(() => import('./messageTemplates/messageTemplateEdit'))}
                                       exact/>
                                <Route path={`${match.url}message-templates`}
                                       component={asyncComponent(() => import('./messageTemplates'))} exact/>
                                <Route path={`${match.url}bulk/sms`}
                                       component={asyncComponent(() => import('./bulk/sms'))}/>
                                <Route path={`${match.url}contacts/import`}
                                       component={asyncComponent(() => import('./contacts/contactsFileImport'))} exact/>
                                <Route path={`${match.url}contacts/:phoneNumber`}
                                       component={asyncComponent(() => import('./contacts/contactDetails'))} exact/>
                                <Route path={`${match.url}contacts`}
                                       component={asyncComponent(() => import('./contacts'))} exact/>
                                <Route path={`${match.url}settings`}
                                       component={asyncComponent(() => import('./settings'))} exact/>
                                <Route path={`${match.url}unsubscribe-page-settings`}
                                       component={asyncComponent(() => import('./settings/unsubscribingPageSettings'))} exact/>
                                <Route path={`${match.url}urls/new`}
                                       component={asyncComponent(() => import('./urls/urlAdd'))} exact/>
                                <Route path={`${match.url}urls/edit/:url`}
                                       component={asyncComponent(() => import('./urls/urlEdit'))} exact/>
                                <Route path={`${match.url}urls`}
                                       component={asyncComponent(() => import('./urls'))} exact/>
                                <Route path={`${match.url}block-list/new`}
                                       component={asyncComponent(() => import('./blockList/newBlockContact'))} exact/>
                                <Route path={`${match.url}block-list`}
                                       component={asyncComponent(() => import('./blockList'))} exact/>
                                <Route path={`${match.url}videos/new`}
                                       component={asyncComponent(() => import('./videos/videoAdd'))} exact/>
                                <Route path={`${match.url}videos/edit/:videoId`}
                                       component={asyncComponent(() => import('./videos/videoEdit'))} exact/>
                                <Route path={`${match.url}videos`}
                                       component={asyncComponent(() => import('./videos'))} exact/>
                                <Route path={`${match.url}custom-fields/new`}
                                       component={asyncComponent(() => import('./customFields/customFieldAdd'))} exact/>
                                <Route path={`${match.url}custom-fields`}
                                       component={asyncComponent(() => import('./customFields'))} exact/>
                                <Route path={`${match.url}reports/bulks/:bulkId`}
                                       component={asyncComponent(() => import('./bulk/details'))} exact/>
                                <Route path={`${match.url}reports/bulks`}
                                       component={asyncComponent(() => import('./bulk/reports'))} exact/>
                                <Route path={`${match.url}reports/contact-imports`}
                                       component={asyncComponent(() => import('./contacts/importList'))}/>
                                <Route path={`${match.url}reports/chats`}
                                       component={asyncComponent(() => import('./chats/reports'))}/>
                                <Route path={`${match.url}keywords/edit/:id`}
                                       component={asyncComponent(() => import('./campaignKeywords/campaignKeywordEdit'))}
                                       exact/>
                                <Route path={`${match.url}keywords/new`}
                                       component={asyncComponent(() => import('./campaignKeywords/campaignKeywordAdd'))}
                                       exact/>
                                <Route path={`${match.url}keywords`}
                                       component={asyncComponent(() => import('./campaignKeywords'))} exact/>

                                {isPopupEnabled && (
                                    <>
                                        <Route path={`${match.url}images/new`}
                                               component={asyncComponent(() => import('./images/imageAdd'))} exact/>
                                        <Route path={`${match.url}images`}
                                               component={asyncComponent(() => import('./images'))} exact/>
                                        <Route path={`${match.url}popups/edit/:id`}
                                               component={asyncComponent(() => import('./widget/popups/popupEdit'))}
                                               exact/>
                                        <Route path={`${match.url}popups/new`}
                                               component={asyncComponent(() => import('./widget/popups/popupAdd'))}
                                               exact/>
                                        <Route path={`${match.url}popups/installation`}
                                               component={asyncComponent(() => import('./widget/popups/popupInstallation'))}
                                               exact/>
                                        <Route path={`${match.url}popups`}
                                               component={asyncComponent(() => import('./widget/popups'))} exact/>
                                        <Route path={`${match.url}coupons/import/list`}
                                               component={asyncComponent(() => import('./coupons/couponImportsList'))}
                                               exact/>
                                        <Route path={`${match.url}coupons/import`}
                                               component={asyncComponent(() => import('./coupons/couponsImport'))}
                                               exact/>
                                        <Route path={`${match.url}coupons`}
                                               component={asyncComponent(() => import('./coupons/couponsList'))} exact/>
                                        <Route path={`${match.url}landing-pages/edit/:id`}
                                               component={asyncComponent(() => import('./widget/landingPages/landingPageEdit'))}
                                               exact/>
                                        <Route path={`${match.url}landing-pages/new`}
                                               component={asyncComponent(() => import('./widget/landingPages/landingPageAdd'))}
                                               exact/>
                                        <Route path={`${match.url}landing-pages`}
                                               component={asyncComponent(() => import('./widget/landingPages'))} exact/>
                                        <Route path={`${match.url}embedded-newsletters/edit/:id`}
                                               component={asyncComponent(() => import('./widget/embeddedNewsletters/embeddedNewsletterEdit'))}
                                               exact/>
                                        <Route path={`${match.url}embedded-newsletters/new`}
                                               component={asyncComponent(() => import('./widget/embeddedNewsletters/embeddedNewsletterAdd'))}
                                               exact/>
                                        <Route path={`${match.url}embedded-newsletters/installation`}
                                               component={asyncComponent(() => import('./widget/embeddedNewsletters/embeddedNewsletterInstallation'))}
                                               exact/>
                                        <Route path={`${match.url}embedded-newsletters`}
                                               component={asyncComponent(() => import('./widget/embeddedNewsletters'))}
                                               exact/>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

            </Switch>
        </div>
    );
};

const mapStateToProps = (state) => {
    const isBulkMessagingEnabled = (process.env.REACT_APP_IS_BULK_MESSAGING_ENABLED === "true" || state.accounts.bulkMessagingEnabled) && state.global.permissions?.includes(BULK_MESSAGING);
    const isPopupEnabled = state.accounts.popupEnabled;
    const isWithoutOverdue = state.accounts.status !== AccountStatus.OVERDUE;
    const isLoadedPhoneNumbers = state.accounts.isLoadedPhoneNumbers;
    return {isBulkMessagingEnabled, isPopupEnabled, isWithoutOverdue, isLoadedPhoneNumbers}
};

export default withRouter(connect(mapStateToProps)(App));

