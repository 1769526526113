import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import { injectIntl } from 'react-intl';
import {Link} from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import SidebarLogo from "./SidebarLogo";
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import UserProfile from "./UserProfile";
import { hideLeftSidebar, initializeWebSocketsChannel, setChatId } from "../../appRedux/actions";
import { isStripeEnabled } from "../../util/stripeService";
import { isTrialExpiredSelector, trialTimeSelector } from "../../appRedux/reducers/Accounts";
import { isLivechatApp, isShoperApp } from "../../util/appType";
import { BULK_MESSAGING } from "../../util/permissions";
import { AccountStatus } from "../../util/account";

class SidebarContent extends Component {
  state = {
    initializeWebSocketRequestSent: false
  };

  doInitWs = (jwt) => {
    if (!this.state.initializeWebSocketRequestSent) {
      // TODO: definitely not the right place to put in, but is invoked only once, needs refactor then 😢
      // Communicate with mobile app
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'loggedIn',
          payload: jwt
        }));

        // TODO: it's not the proper place for the event listener too

        const handleMessage = message => {
          console.log(message);

          if (message.data) {
            const json = JSON.parse(message.data);

            if (json.type === 'GO_TO_CONVERSATION') {
              setChatId(json.payload.conversationId);
            }
          }
        }
        window['goToConversation'] = handleMessage;
        document.addEventListener("message", handleMessage);
        window.addEventListener("message", handleMessage);
      }

      this.setState({
        initializeWebSocketRequestSent: true
      }, () => this.props.initializeWebSocketsChannel(jwt))
    }
  };

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  getCurrentMenuKey = key => {
    const result = window.location.pathname.startsWith(key) ? window.location.pathname : key;
    return result.replace(/^\//, '');
  }

  render() {
    const {pathname, accountName, trialFinishDate, isTrialExpired, trialTime, isBulkMessagingEnabled, isPopupEnabled, isWithoutOverdue, intl } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (<Auxiliary>

        {!isShoperApp() && (
            <SidebarLogo/>
        )}

        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              mode="inline"
            >
              {!isShoperApp() && !isLivechatApp() && isWithoutOverdue && (
                <Menu.Item key="my-dashboard" title={intl.formatMessage({id: 'sidebar.dashboard'})}>
                  <Link onClick={this.props.hideLeftSidebar} to="/my-dashboard">
                    <IntlMessages id="sidebar.dashboard"/>
                  </Link>
                </Menu.Item>
              )}

              {!isShoperApp() && !isLivechatApp() && isWithoutOverdue && (
                <Menu.Item key="chat" title={intl.formatMessage({id: 'sidebar.chats'})}>
                  <Link onClick={this.props.hideLeftSidebar} to="/chat?id=&tab=2">
                    <IntlMessages id="sidebar.chats"/>
                  </Link>
                </Menu.Item>
              )}

              {!isShoperApp() && !isLivechatApp() && isWithoutOverdue && (
                <Menu.Item key="my-archives" title={intl.formatMessage({id: 'sidebar.archives'})}>
                  <Link onClick={this.props.hideLeftSidebar} to="/my-archives">
                    <IntlMessages id="sidebar.archives"/>
                  </Link>
                </Menu.Item>
              )}

              {this.props.isZenonAccount && isWithoutOverdue && (
                  <Menu.Item key="phone-book" title={intl.formatMessage({id: 'sidebar.phoneBook'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/phone-book">
                          <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.phoneBook" style={{color: '#2C2F33'}}/></span>
                      </Link>
                  </Menu.Item>
              )}

              {this.props.isZenonAccount && isWithoutOverdue && (
                  <Menu.Item key="bulk-send" title={intl.formatMessage({id: 'sidebar.bulkSend'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/bulk-send">
                          <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.bulkSend" /></span>
                      </Link>
                  </Menu.Item>
              )}

              {isBulkMessagingEnabled && !this.props.isZenonAccount && isWithoutOverdue && (
              <Menu.Item key={this.getCurrentMenuKey('/contacts')} title={intl.formatMessage({id: 'contacts'})}>
                <Link onClick={this.props.hideLeftSidebar} to="/contacts">
                  <span style={{color: '#2C2F33'}}><IntlMessages id="contacts" style={{color: '#2C2F33'}}/></span>
                </Link>
              </Menu.Item>
              )}

              {isBulkMessagingEnabled && !this.props.isZenonAccount && isWithoutOverdue && (
                <Menu.Item key="bulk/sms" title={intl.formatMessage({id: 'bulkSms.newBulk'})}>
                  <Link onClick={this.props.hideLeftSidebar} to="/bulk/sms">
                    <span style={{color: '#2C2F33'}}><IntlMessages id="bulkSms.newBulk" style={{color: '#2C2F33'}}/></span>
                  </Link>
                </Menu.Item>
              )}

              {isBulkMessagingEnabled && !this.props.isZenonAccount && isWithoutOverdue && (
                <Menu.SubMenu key="sub1" title={intl.formatMessage({id: 'settings.title'})}>
                  <Menu.Item key="phoneNumbers" title={intl.formatMessage({id: 'phoneNumbers'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/phone-numbers">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="phoneNumbers" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={this.getCurrentMenuKey('/message-templates')} title={intl.formatMessage({id: 'messageTemplates'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/message-templates">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="messageTemplates" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={this.getCurrentMenuKey('/urls')} title={intl.formatMessage({id: 'urls'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/urls">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="urls" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={this.getCurrentMenuKey('/videos')} title={intl.formatMessage({id: 'videos'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/videos">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="videos" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  {isPopupEnabled && (
                    <Menu.Item key={this.getCurrentMenuKey('/images')} title={intl.formatMessage({id: 'images'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/images">
                        <span style={{color: '#2C2F33'}}><IntlMessages id="images" style={{color: '#2C2F33'}}/></span>
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item key={this.getCurrentMenuKey('/block-list')} title={intl.formatMessage({id: 'blockList'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/block-list">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="blockList" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={this.getCurrentMenuKey('/custom-fields')} title={intl.formatMessage({id: 'customFields'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/custom-fields">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="customFields" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={this.getCurrentMenuKey('/unsubscribe-page-settings')} title={intl.formatMessage({id: 'sidebar.unsubscribePageSettings'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/unsubscribe-page-settings">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.unsubscribePageSettings" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="settings" title={intl.formatMessage({id: 'account'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/settings">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="account" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>
                </Menu.SubMenu>
              )}

              {isBulkMessagingEnabled && !this.props.isZenonAccount && isWithoutOverdue && (
                  <Menu.SubMenu key="sub2" title={intl.formatMessage({id: 'sidebar.reports'})}>
                    <Menu.Item key={this.getCurrentMenuKey('/reports/bulks')} title={intl.formatMessage({id: 'sidebar.bulkReports'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/reports/bulks">
                        <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.bulkReports" style={{color: '#2C2F33'}}/></span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="reports/contact-imports" title={intl.formatMessage({id: 'contactImports'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/reports/contact-imports">
                        <span style={{color: '#2C2F33'}}><IntlMessages id="contactImports" style={{color: '#2C2F33'}}/></span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="reports/chats" title={intl.formatMessage({id: 'sidebar.chats'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/reports/chats">
                        <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.chats" style={{color: '#2C2F33'}}/></span>
                      </Link>
                    </Menu.Item>
                  </Menu.SubMenu>
              )}

              {!isShoperApp() && !isLivechatApp() && (
                <Menu.SubMenu key="sub3" title={intl.formatMessage({id: 'billing'})}>
                  {isStripeEnabled(accountName) && (
                      <Menu.Item key="new-plans" title={intl.formatMessage({id: 'sidebar.plans'})}>
                        <Link onClick={this.props.hideLeftSidebar} to="/new-plans">
                          <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.plans" style={{color: '#2C2F33'}}/></span>
                        </Link>
                      </Menu.Item>
                  )}

                  {isStripeEnabled(accountName) && (
                      <Menu.Item key="payments" title={intl.formatMessage({id: 'sidebar.payments'})}>
                        <Link onClick={this.props.hideLeftSidebar} to="/payments">
                          <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.payments" style={{color: '#2C2F33'}}/></span>
                        </Link>
                      </Menu.Item>
                  )}

                  <Menu.Item key="balance" title={intl.formatMessage({id: 'sidebar.balance'})}>
                    <Link onClick={this.props.hideLeftSidebar} to="/balance">
                      <span style={{color: '#2C2F33'}}><IntlMessages id="sidebar.balance" style={{color: '#2C2F33'}}/></span>
                    </Link>
                  </Menu.Item>
                </Menu.SubMenu>
              )}

              {isBulkMessagingEnabled && !this.props.isZenonAccount && isWithoutOverdue && (
                  <Menu.SubMenu key="sub4" title={intl.formatMessage({id: 'sidebar.newsletter'})}>
                    <Menu.Item key={this.getCurrentMenuKey("/keywords")} title={intl.formatMessage({id: 'keywords'})}>
                      <Link onClick={this.props.hideLeftSidebar} to="/keywords">
                        <span style={{color: '#2C2F33'}}><IntlMessages id="keywords" style={{color: '#2C2F33'}}/></span>
                      </Link>
                    </Menu.Item>

                    {isPopupEnabled && (
                        <>
                          <Menu.Item key={this.getCurrentMenuKey("/popups")} title={intl.formatMessage({id: 'popups'})}>
                            <Link onClick={this.props.hideLeftSidebar} to="/popups">
                              <span style={{color: '#2C2F33'}}><IntlMessages id="popups" style={{color: '#2C2F33'}}/></span>
                            </Link>
                          </Menu.Item>

                          <Menu.Item key={this.getCurrentMenuKey("/landing-pages")} title={intl.formatMessage({id: 'landingPages'})}>
                            <Link onClick={this.props.hideLeftSidebar} to="/landing-pages">
                              <span style={{color: '#2C2F33'}}><IntlMessages id="landingPages" style={{color: '#2C2F33'}}/></span>
                            </Link>
                          </Menu.Item>

                          <Menu.Item key={this.getCurrentMenuKey("/embedded-newsletters")} title={intl.formatMessage({id: 'embeddedNewsletters'})}>
                            <Link onClick={this.props.hideLeftSidebar} to="/embedded-newsletters">
                              <span style={{color: '#2C2F33'}}><IntlMessages id="embeddedNewsletters.embedded" style={{color: '#2C2F33'}}/></span>
                            </Link>
                          </Menu.Item>

                          <Menu.Item key={this.getCurrentMenuKey("/coupons")} title={intl.formatMessage({id: 'coupons'})}>
                            <Link onClick={this.props.hideLeftSidebar} to="/coupons">
                              <span style={{color: '#2C2F33'}}><IntlMessages id="coupons" style={{color: '#2C2F33'}}/></span>
                            </Link>
                          </Menu.Item>
                        </>
                    )}
                  </Menu.SubMenu>
              )}

              {!isShoperApp() ? (
                <Menu.Item key="user-profile" className="gx-chat-user-item margin-top-auto user-logo">
                    {trialFinishDate && (
                    <div className="trial-info">
                      <span className="trial-text">
                        {isTrialExpired
                            ? <IntlMessages id="sidebar.trialExpired" />
                            : <IntlMessages id="sidebar.trialWillExpire" />}
                      </span>
                      <strong>{trialTime}</strong>
                    </div>
                    )}
                    <UserProfile initWs={this.doInitWs}/>
                </Menu.Item>
              ) : <UserProfile initWs={this.doInitWs} hiddenHtml={true}/>}

{/*              <Menu.Item key="bubble" title={intl.formatMessage({id: 'bubble'})}>
                <Link onClick={this.props.hideLeftSidebar} to="/bubble">
                  <span style={{color: '#2C2F33'}}><IntlMessages id="bubble" style={{color: '#2C2F33'}}/></span>
                </Link>
              </Menu.Item>*/}

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = (state) => {
  const {navStyle, themeType, locale, pathname} = state.settings;
  const isZenonAccount = !!state.accounts.phoneNumbersAll.find(number => number.isZenon);
  const isTrialExpired = isTrialExpiredSelector(state);
  const trialTime = trialTimeSelector(state);
  const isBulkMessagingEnabled = (process.env.REACT_APP_IS_BULK_MESSAGING_ENABLED === "true" || state.accounts.bulkMessagingEnabled) && state.global.permissions?.includes(BULK_MESSAGING);
  const isPopupEnabled = state.accounts.popupEnabled;
  const isWithoutOverdue = state.accounts.status !== AccountStatus.OVERDUE;
  return {navStyle, themeType, locale, pathname, isZenonAccount, accountName: state.accounts.accountName, trialFinishDate: state.accounts.trialFinishDate, isTrialExpired, trialTime, isBulkMessagingEnabled, isPopupEnabled, isWithoutOverdue }
};
export default injectIntl(connect(mapStateToProps, {initializeWebSocketsChannel, setChatId, hideLeftSidebar})(SidebarContent));

